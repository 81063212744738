.truck-images {
  display: flex;
  overflow: hidden;
}

.truck-images-main {
  height: 50vh;
  min-height: 400px;
}

.truck-images-others {
  height: 30vh;
  min-height: 200px;
}

.truck-images > div {
  width: 100%;
}

.truck-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top-bottom-images > div {
  height: 50%;
  display: flex;
}

.top-bottom-images > div > div {
  margin-left: 5px;
  width: 50%;
}

.top-bottom-images > div:last-child {
  margin-top: 5px;
}

.truck-details {
  margin-top: 20px;
  padding: 0 20px;
}

.truck-details h4 {
  font-size: 110%;
  margin: 0;
  margin-top: 20px;
}
.truck-details p {
  margin: 0;
}
