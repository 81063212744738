.content-details > .content {
  background-color: var(--div-bg);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 40px;
  padding-left: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.content {
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
}
/* 
table {
  width: 100%;
  margin: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

table tr {
  border-bottom: 1px solid var(--div-bg);
}
table tr td {
  border-left: 1px solid var(--div-bg);
  padding: 3px 10px;
  font-size: 85%;
  color: var(--head-color);
}
thead tr {
  background: var(--tr-color);
}
th {
  text-align: start;
  padding: 10px;
  font-weight: 600;
  font-size: 85%;
}

tbody tr:nth-child(odd) {
  background: var(--tr-odd-color);
}

tbody tr:hover {
  background: var(--tr-odd-hover-color);
} */

.content-header {
  font-weight: 600;
  font-size: 100%;
  margin-bottom: 25px;
}

.content-header-container {
  margin-left: 15px;
}

.ant-pagination-options {
  visibility: hidden;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
}
.pagination-container > div {
  display: flex;
}

.pagination-container p {
  padding-top: 5px;
  margin: 0;
}
.pagination-container .ant-pagination-options {
  visibility: visible;
}

.pagination-container .ant-pagination-item,
.pagination-container .ant-pagination-item-link,
.pagination-container .ant-pagination-jump-next,
.pagination-container .ant-pagination-next,
.pagination-container .ant-pagination-prev {
  display: none;
}

.ant-menu,
.ant-menu-item,
.ant-menu-sub,
.ant-menu-submenu {
  /* background-color: var(--div-bg) !important; */

  background: rgb(16, 23, 41) !important;
  color: rgb(209, 209, 209) !important;
  font-weight: 500;
}

.ant-menu-item {
  height: 45px !important;
}

.ant-menu-item-only-child {
  height: 40px !important;
}

.ant-menu-submenu-arrow {
  color: rgb(209, 209, 209);
  /* color: var(--drop-color); */
}

.ant-menu-item-selected,
.ant-menu-item:hover,
.ant-menu-submenu-title:hover {
  background-color: rgb(46, 51, 67) !important;
  color: white !important;
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.ant-menu-submenu-title {
  color: rgb(209, 209, 209) !important;
}
.ant-menu {
  padding: 5px 10px;
}

.ant-menu-item-selected::after {
  display: none;
}

.ant-menu-item svg,
.ant-menu-submenu svg {
  /* outline: rgb(180, 180, 180); */
  font-size: 20px !important;
  margin-right: 5px;
  margin-left: -5px;
}

.sized {
  max-width: 250px;
  word-wrap: break-word;
}

.space-btn {
  width: 10px;
}

.no-data {
  width: 100%;
  margin: 20px 0;
  text-align: center;
  font-weight: 500;
}

.ant-input-group {
  width: unset;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* gap: 10px; */
}

.post-cargo p {
  margin: 0;
  margin-bottom: 5px;
  margin-top: 10px;
}

.marker_pop_up h6,
.marker_pop_up p {
  font-size: 90%;
  margin: 0;
}

.cargo-table table {
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
}
