.three-image {
  display: flex;
  height: 500px;
}

.three-image > div {
  width: 100%;
  height: 100%;
}

.for-three > div:last-child {
  width: 70%;
}

.three-image > div > div {
  height: 50%;
}

.other-image > div:last-child {
  padding-left: 2px;
}

.other-image > div:first-child {
  padding-right: 2px;
}

.single-image img {
  object-fit: contain;
}

.other-image > div img,
.three-image img {
  object-fit: cover;
}

.other-image {
  display: flex;
  /* height: 100px; */
}

.other-image > div {
  padding-top: 2px;
  width: 100%;
  height: 300px;
}

.loader-more-spinner {
  display: flex;
  padding: 20px;
  justify-content: center;
}

.write-comment {
  display: flex;
  margin: 5px 0;
}

.write-comment input {
  background-color: var(--bg);
  width: 100%;
  font-size: 80%;
  border: none;
  margin-left: 10px;
  border-radius: 50px;
  padding: 0 10px;
}

.write-comment input:focus {
  outline: none;
}

.img-previews {
  margin-top: 20px;
  width: 400px;
  height: 300px;
  overflow: hidden;
}
