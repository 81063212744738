.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 20px;
  padding-bottom: 20px;

  padding-left: 15px;
  padding-right: 15px;
  border-radius: 8px;
  background-color: var(--div-bg);
  margin: 0 auto !important;
}
.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

.Auth-form-content input {
  padding: 15px;
}

.Auth-form-content button {
  padding: 13px !important;
  width: 100%;
  margin-top: 20px;
  background-color: #d1b000;
  border: 1px solid #d1b000;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.Auth-form-content button:hover {
  cursor: pointer;
  background-color: #f2cf06;
  border: 1px solid #f2cf06;
}
.pieme-side {
  width: 50%;
  padding-left: 20px;
  /* height: 100%; */
}

.pieme-side h2 {
  font-size: 400%;
  font-weight: 800;
  color: rgb(33, 105, 194);
}

.ant-modal-content {
  background-color: var(--div-bg) !important;
}
.ant-modal-close {
  color: var(--head-color) !important;
}

.pieme-side > div {
  display: flex;
}

.pieme-side > div > img {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

.pieme-side p {
  font-size: 160%;
  font-weight: 400;
  color: var(--sub-head-color);
}

.ant-modal-footer {
  border: none !important;
}

.pieme-sides {
  width: 420px;
  display: none;
}

.pieme-sides h2 {
  font-size: 220%;
  font-weight: 800;
  color: var(--pieme);
}

.pieme-sides p {
  font-size: 120%;
}

@media only screen and (max-width: 800px) {
  .pieme-side {
    display: none;
  }

  .pieme-sides {
    width: 420px;
    display: block;
  }

  .Auth-form-container {
    justify-content: center;
  }
}
