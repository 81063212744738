.nav-bar {
  /* width: 280px; */
  /* min-width: 280px; */
  overflow: hidden;
  overflow-y: scroll;
  height: 100vh;
  /* background-color: var(--div-bg); */
  /* background: rgb(2, 0, 36); */
  /* background: linear-gradient(
      360deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(1, 30, 57, 1) 100%
    ); */
  width: 100%;
  background-color: rgb(16, 23, 41);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.nav-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-bar h5 {
  font-weight: 600;
  font-size: 150%;
  margin-top: 30px;
  text-align: center;
  /* color: var(--head-color); */
  color: white;
  margin-bottom: 30px;
}

.nav-bar .small-section {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-bar > .small-section > img {
  width: 50px;
}

.nav-bar .avatar {
  display: flex;
  width: 100%;
  justify-content: center;
}

.nav-bar h6 {
  font-weight: 600;
  font-size: 100%;
  margin: 0;
  margin-bottom: 5px;
  margin-top: 10px;
  text-align: center;
  color: white;
}

.nav-bar p {
  font-size: 85%;
  text-align: center;
  margin-top: 0;
  color: rgb(199, 199, 199);
}
.header {
  height: 60px;
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 20px -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}

.header button {
  background-color: transparent;
  border: none;
  margin: 0 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.header button:hover {
  cursor: pointer;
  background-color: rgb(231, 231, 231);
}

.link_to {
  color: rgb(27, 27, 27);
  text-decoration: none;
}

.link_to:hover {
  color: rgb(45, 121, 246);
}
