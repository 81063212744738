/* Custom Dark Mode Toggle Element */
.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding-top: 6px;
  margin-left: 15px;
}

.toggle-theme-wrapper span {
  font-size: 25px;
}

.toggle-theme {
  position: relative;
  display: inline-block;
  height: 25px;
  width: 50px;
}

.toggle-theme input {
  display: none;
}

.slider {
  background-color: #ccc;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.2s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 17px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 17px;
}

input:checked + .slider:before {
  transform: translateX(25px);
  -webkit-transform: translateX(25px);
  -moz-transform: translateX(25px);
  -ms-transform: translateX(25px);
  -o-transform: translateX(25px);
}

input:checked + .slider {
  background-color: cornflowerblue;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* 1 */
:root {
  --font-color: #333;
  --background-color: rgb(255, 255, 255);
  --link-color: rgb(193, 222, 239);
  --drop-color: rgb(40, 40, 40);
  --bg: #f0f2f5;
  --div-bg: white;
  --border: rgb(231, 231, 231);
  --head-color: rgb(51, 51, 51);
  --sub-head-color: gray;
  --border-line: rgb(231, 231, 231);
  --icon-color: "";
  --tr-color: rgb(245, 245, 245);
  --tr-odd-color: rgb(250, 250, 250);
  --tr-odd-hover-color: rgb(230, 230, 230);
}

/* 2 */
[data-theme="dark"] {
  --font-color: #eee;
  --bg: rgb(0, 0, 0);
  --link-color: rgb(11, 107, 163);
  --background-color: rgb(34, 34, 34);
  --drop-color: rgb(232, 246, 254);
  --div-bg: rgb(14, 17, 22);
  /* --div-bg: rgb(30, 34, 38); */
  --border: rgb(25, 18, 23);
  --border-line: rgb(24, 25, 26);
  --head-color: rgb(218, 218, 218);
  --sub-head-color: #9c9c9c;
  --icon-color: invert(78%) sepia(37%) saturate(16%) hue-rotate(83deg)
    brightness(93%) contrast(86%);

  --tr-color: rgb(23, 23, 23);
  --tr-odd-color: rgb(17, 20, 22);
  --tr-odd-hover-color: rgb(25, 25, 22);
}

/* 3 */
body {
  background-color: var(--bg) !important;
  /* color: var(--font-color) !important; */
}

a {
  /* color: var(--link-color); */
}
