.dashboard-item {
  background-color: white;
  margin: 10px 5px;
  padding: 20px 10px 25px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dashboard-item img {
  width: 40%;
  height: 100px;
  object-fit: contain;
}

.dashboard-item h5 {
  margin-top: 10px;
  font-size: 200%;
  font-weight: 800;
}

.dashboard-item p {
  font-size: 110%;
  font-weight: 450;
  margin: 0;
}

.dashboard h6 {
  font-size: 120%;
  margin-left: 20px;
  margin-top: 0;
}
.dashboard-item {
  display: flex;
  justify-content: space-between;
}

.bar-chart {
  padding: 20px;
  background-color: white;
  margin: 5px;
  /* height: 500px; */
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.containers {
  overflow: auto;
  width: 100%;
}

.containers img {
  width: calc(100% / 3);
  float: left;
  cursor: pointer;
  padding-right: 5px;
  user-select: none;
  height: 200px;
  object-fit: cover;
  margin: 3px 0;
}
