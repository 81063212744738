@import "antd/dist/reset.css";
@import "./assets/style/navbar.css";
@import "./assets/style/content.css";
@import "./assets/style/dark_mode.css";
@import "./assets/style/dashboard.css";
@import "./assets/style/login.css";
@import "./assets/style/truck.css";
@import "./assets/style/collage.css";
@import "bootstrap/dist/css/bootstrap.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

table {
  /* border: 1px solid rgba(200, 200, 200, 0.4); */
  width: 100%;
  margin: 20px 0;
  font-size: 0.875rem;
  overflow-x: auto;
}

table,
th,
td {
  border: 1px solid rgba(200, 200, 200, 0.4);
  border-collapse: collapse;
  padding: 8px 15px;
  text-wrap: nowrap;
}

th {
  padding: 10px 15px;
  background-color: rgb(220, 220, 220);
}

/* 

thead tr {
  background-color: rgb(220, 220, 220);
  z-index: 999999;
}
thead tr td {
  font-weight: 600;
}
th,
td {
  border-left: 1px solid rgba(200, 200, 200, 0.4);
  border-collapse: collapse;
  padding: 7px 15px;
  font-size: 14px;
  border-bottom: 1px solid rgba(200, 200, 200, 0.4);
  overflow: hidden;
}

th {
  text-align: start;
  font-weight: 600;
}
tbody tr:nth-child(odd) {
  background-color: #f5f5f5;
}

tr:hover {
  background-color: #dadada !important;
} */

.ant-btn-primary {
  background-color: #1677ff;
  z-index: 99 !important;
}

.overload-table table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.ant-menu-title-content {
  font-weight: 400;
}

.msg input,
.msg input:focus,
.msg input:active {
  outline: none;
}

.chat-message {
  height: calc(100vh - 14rem) !important;
}
